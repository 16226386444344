import React, { useState,useEffect } from 'react';
import "./logo.css";
import {useNavigate} from "react-router-dom";



const Comp =()=>{
    const nav = useNavigate();
    useEffect(()=>{
        setTimeout(()=>{
            nav('/main',{replace:true});
        },3000)
    },[])
    return(<div className="body">
        <div className="txt" contentEditable="true">
            Polithedrum
        </div>
    </div>);
}

export default Comp;