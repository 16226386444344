import React from 'react'
import Avatar from '../Avatars/avatars';

const CinimaSeats = () => {
    // todo - delete or use - wasnt used before
    // const Avatars = Avatar;
 
    return (
        <>
            <div className="cinema-row row-1">
                <div className="seat">{<Avatar index={2}/>}</div>
                <div className="seat">{<Avatar index={3}/>}</div>
                <div className="seat">{<Avatar index={1}/>}</div>
                <div className="seat">{<Avatar index={5}/>}</div>
            </div>
            <div className="cinema-row row-2">
                <div className="seat"></div>
                <div className="seat"></div>
                <div className="seat"></div>
                <div className="seat"></div>
            </div>
        </>
    )
}

export default CinimaSeats
