const daniel_key =`30cdac056bd36b55dbc48d98b4891c29`;

const arkadi_key =`cadc738ae2cba3e3c454578f77f24045`;

const flag = false;

export const key = flag?arkadi_key:daniel_key;



export const baseUrl = "https://api.themoviedb.org/3/";