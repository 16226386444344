import React, { useContext, createContext, useState, useEffect } from 'react';
import { app, loadProfile } from './FireBase';
import { getAuth, onAuthStateChanged } from "firebase/auth"
import LoadingPage from '../globalComponents/LoadingPage';


export const ConnectedUserContext = createContext();


export function useUserContext() {
    return useContext(ConnectedUserContext)
}

export const AuthContextProvider = ({ children }) => {

    const auth = getAuth(app);
    const [loading, setLoading] = useState(true)
    const [currentUser, setCurrentUser] = useState()
    const [profile, setProfile] = useState()
    useEffect(() => {
        const subscription = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user)
                loadProfile({ profile, setProfile })
            } else {
                setCurrentUser()
                setProfile({
                    userProfileName: 'Guest',
                    userAvatar: 0
                })
            }
        })
        setLoading(false)
        return () =>
            subscription();
    }, [currentUser])
    const value = { currentUser, profile,setProfile }
    return (
        <ConnectedUserContext.Provider value={value}>
            {loading ? <LoadingPage /> : children}
        </ConnectedUserContext.Provider>
    )
}

