import { key , baseUrl } from "./key";
import axios from "axios";

const requests = {
    getTrending: `/trending/all/week?api_key=${key}&language=en-US`,
    getTopTated: `/discover/tv?api_key=${key}&with_networks=213`,
    getPopuler: `/movie/popular?api_key=${key}&language=en-US&page=1`,
    getAdventureList: `/discover/movie?api_key=${key}&language=en-US&sort_by=popularity.desc&page=1&with_genres=12`,
    getActionList: `/discover/movie?api_key=${key}&language=en-US&sort_by=popularity.desc&page=1&with_genres=28`,
    getComedyList: `/discover/movie?api_key=${key}&language=en-US&sort_by=popularity.desc&page=1&with_genres=35`,
    getFantasyList: `/discover/movie?api_key=${key}&language=en-US&sort_by=popularity.desc&page=1&with_genres=14`,
    getAnimationList: `/discover/movie?api_key=${key}&language=en-US&sort_by=popularity.desc&page=1&with_genres=16`,
    getRomanceList: `/discover/movie?api_key=${key}&language=en-US&sort_by=popularity.desc&page=1&with_genres=10749`,
    getDocumentaryList: `/discover/movie?api_key=${key}&language=en-US&sort_by=popularity.desc&page=1&with_genres=99`,
    getDramaList: `/discover/movie?api_key=${key}&language=en-US&sort_by=popularity.desc&page=1&with_genres=18`,
    getScienceFictionList: `/discover/movie?api_key=${key}&language=en-US&sort_by=popularity.desc&page=1&with_genres=878`,
    getHorrorList: `/discover/movie?api_key=${key}&language=en-US&sort_by=popularity.desc&page=1&with_genres=27`,
    list: `/genre/movie/list?api_key=${key}&language=en-US`,
    getTrailersPart1:`/movie/`,
    getTrailersPart2:`/videos?api_key=${key}&language=en-US`
    //https://api.themoviedb.org/3/movie/{movie_id}/videos?api_key=<<api_key>>&language=en-US
}

export async function getMovieData(id) {
    try {
        return axios.get(baseUrl + 'movie/' + id + `?api_key=${key}`)
    }
    catch (e) {
        console.log(e + ' Error from get movie data - 78')
    }

}

export async function fetchmovies(){
    const {data:{results}} = await axios.get(`${baseUrl}discover/movie`,{
        params:{
            api_key:key
        }
    });
}

export default requests;
/*genres: Array(19)
0: {id: 28, name: 'Action'}
1: {id: 12, name: 'Adventure'}
2: {id: 16, name: 'Animation'}
3: {id: 35, name: 'Comedy'}
4: {id: 80, name: 'Crime'}
5: {id: 99, name: 'Documentary'}
6: {id: 18, name: 'Drama'}
7: {id: 10751, name: 'Family'}
8: {id: 14, name: 'Fantasy'}
9: {id: 36, name: 'History'}
10: {id: 27, name: 'Horror'}
11: {id: 10402, name: 'Music'}
12: {id: 9648, name: 'Mystery'}
13: {id: 10749, name: 'Romance'}
14: {id: 878, name: 'Science Fiction'}
15: {id: 10770, name: 'TV Movie'}
16: {id: 53, name: 'Thriller'}
17: {id: 10752, name: 'War'}
18: {id: 37, name: 'Western'}
*/