import React,{useRef, useState} from 'react'
import {signUpWithMail,signUpWithGoogle} from '../FireBase'
import { Link ,useNavigate} from 'react-router-dom';

function SignUp () {
    const emailRef = useRef("")
    const passwordRef = useRef("")
    const confPasswordRef = useRef("")
    const profileNameRef = useRef("")
    const [alert, setAlert] = useState('')
    const [loading,setLoading]=useState(false)
    const nav = useNavigate();

    async function heandleSubmit(e){
        e.preventDefault();
        if(passwordRef.current.value===confPasswordRef.current.value){
            try{
                setLoading(true)
                setAlert("");
                await signUpWithMail(emailRef.current.value,passwordRef.current.value,profileNameRef.current.value)
                nav('/sign_in',{replace:true});
            }catch{
                setAlert("Faild to create account")
            }
    }else{
        setAlert("Passwords dont match");
    }
            setLoading(false)
             return
    }
    return (
        <>
        <div className='container'style={{width:'100%',height:'100vh',alignItems:'center',justifyContent:'center'}}>
        {alert && <div className='danger'>{alert}</div>}
        <form  id='form' className='animated' style={{height:'50vh'}} onSubmit={heandleSubmit}>
        <h2>Sign Up</h2>
            <div className='detail'>
            <div className="form_row">
                    <div className="col-12">
                        <input id='name'  name="firstname" placeholder={'Profile name'} ref={profileNameRef} required />
                    </div>
                </div>
            <div className="form_row">
                    <div className="col-12">
                        <input id='email'  name="firstname" placeholder={'Email'} ref={emailRef} required />
                    </div>
                </div>

                <div className="form_row">
                    <div className="col-12">
                        <input id='password' name='email' placeholder={'password'}  type="password" ref={passwordRef} required />
                    </div>
                </div>
                <div className="form_row">
                    <div className="col-12">
                        <input id='passwordConf' name='email' placeholder={'Confirm Password'}  type="password" ref={confPasswordRef} required />
                    </div>
                </div>
                <div className="form_row">
            <button disabled={loading} type="submit" >Sign up</button>
            <button disabled={loading} onClick={signUpWithGoogle} >Sign Up with Google</button>
            </div>
            </div>
            <div className="w-100 text center mt-2">
            Already have an account? <Link to="/sign_in">Log In</Link>
    </div>
        </form>
            

 
</div>
  
        </>
    )
}

export default SignUp
