
import React from 'react'

const LoadingComponent = () => {
    return (
        <div className="load_component">
        <span>↓</span>
        <span style={{animationDelay:'0.1s'}}>↓</span>
        <span style={{animationDelay:'0.3s'}}>↓</span>
        <span style={{animationDelay:'0.4s'}}>↓</span>
        <span style={{animationDelay:'0.5s'}}>↓</span>
      </div>
    )
}

export default LoadingComponent
