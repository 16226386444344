import React, {useEffect, useState} from "react";
import {Link as NavLink, useNavigate} from 'react-router-dom';
import axios from "../tmdbAPI/axios";
import requests from "../tmdbAPI/requests";
import {useUserContext} from "../Auth/ConnectedUserContext";
import {
    addToDislikedList,
    addToLikedList,
    addToWatchList,
    loadProfile, removeFromDislikedList,
    removeFromLikedList,
    removeFromWatchList
} from "../Auth/FireBase";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faThumbsUp,
    faThumbsDown,
    faPlay, faMinus, faPlus
} from '@fortawesome/free-solid-svg-icons'

import {callCreateNewRoom, img_base_url, OVERLAY, playButton} from '../globalComponents/global_vars';


export default function PopUp({pop,movieInf,url}){

    const handleClick = () => {
        pop(false);
    };
    useEffect(() => {

    }, [url]);
    const {profile, setProfile} = useUserContext();

    function addMovieToWatchList(id) {
        const watchList = profile?.userMovies?.userWatchList;
        if (watchList) {
            addToWatchList(watchList, id)
        } else addToWatchList([], id)

        loadProfile({profile, setProfile})
    }

    function removeMovieFromWatchList(id) {
        const watchList = profile?.userMovies?.userWatchList;
        if (watchList) {
            removeFromWatchList(watchList, id)
        } else removeFromWatchList([], id)

        loadProfile({profile, setProfile})
    }

    function addMovieToUpVotes(id) {
        const movieList = profile?.userMovies?.userUpVotes;
        if (profile?.userMovies?.userDownVotes?.includes(id)) {
            console.log('in down votes, removing')
            removeMovieFromDownVotes(id)
        }
        if (movieList) {
            addToLikedList(movieList, id)
        } else addToLikedList([], id)
        loadProfile({profile, setProfile})
    }

    function removeMovieFromUpVotes(id) {
        const movieList = profile?.userMovies?.userUpVotes;
        if (movieList) {
            removeFromLikedList(movieList, id)
        } else removeFromLikedList([], id)

        loadProfile({profile, setProfile})
    }

    function addMovieToDownVotes(id) {
        const movieList = profile?.userMovies?.userDownVotes;
        if (profile?.userMovies?.userUpVotes?.includes(id))
            removeMovieFromUpVotes(id)

        if (movieList) {
            addToDislikedList(movieList, id)
        } else addToDislikedList([], id)

        loadProfile({profile, setProfile})
    }

    function removeMovieFromDownVotes(id) {
        const movieList = profile?.userMovies?.userDownVotes;
        if (movieList) {
            removeFromDislikedList(movieList, id)
        } else removeFromDislikedList([], id)
        loadProfile({profile, setProfile})
    }

    const [inWatchList, setInWatchList] = useState(profile?.userMovies?.userWatchList?.includes(movieInf.id))
    const [inLikeList, setInLikeList] = useState(profile?.userMovies?.userUpVotes?.includes(movieInf.id))
    const [inDislikeList, setInDislikeList] = useState(profile?.userMovies?.userDownVotes?.includes(movieInf.id))
    const navigate = useNavigate();

    useEffect(() => {
        setInWatchList(profile?.userMovies?.userWatchList?.includes(movieInf.id))
        setInLikeList(profile?.userMovies?.userUpVotes?.includes(movieInf.id))
        setInDislikeList(profile?.userMovies?.userDownVotes?.includes(movieInf.id))
    }, [profile, movieInf.id])

    //buttons
    const addToListButton = {
        width: "3vw",
        height: "5vh",
        color: inWatchList && 'red',
        position: "fixed",
        top: "60vh",
        left: "31vw"
    };
    const LikeButton = {
        width: "3vw",
        height: "5vh",
        color: inLikeList && 'green',
        position: "fixed",
        top: "53vh",
        left: "67vw"
    };
    const dislikeButton = {
        width: "3vw",
        height: "5vh",
        color: inDislikeList && 'red',
        position: "fixed",
        top: "60vh",
        left: "67vw"
    };

    return (<>
        <div style={OVERLAY} onClick={handleClick}/>
        <div className="modal" style={{color: "black"}}>
            <div className="modal_content">
                <div className="banner"
                     style={{
                         backgroundSize: "cover",
                         backgroundImage: `url(${img_base_url}${movieInf?.backdrop_path})`,
                         backgroundPosition: "center center",
                     }}
                >{/*background img*/}
                    <div style={{backgroundColor: "rgba(0,0,0,0.4)"}}>
                        <div className="banner_contents_pop ">
                            {/*title */}
                            <h1 className="banner_title "
                                style={{marginLeft: "10vh"}}>{`${movieInf?.original_title || movieInf?.name || movieInf?.title}`}</h1>
                            {/*discription*/}
                            <h1 className="banner_description " style={{marginLeft: "10vh"}}>
                                {movieInf?.overview}
                            </h1>
                            <div className='movie_description_buttons'>
                                <div className='movie_description_buttons_user'>
                                        <FontAwesomeIcon className="round-button" style={playButton}
                                                         icon={faPlay} onClick={() => callCreateNewRoom(movieInf,profile,navigate)}></FontAwesomeIcon>
                                    <FontAwesomeIcon
                                        onClick={inWatchList ? () => removeMovieFromWatchList(movieInf.id) : () => addMovieToWatchList(movieInf.id)}
                                        icon={inWatchList ? faMinus : faPlus} style={addToListButton}/>
                                </div>
                                <div className='movie_description_buttons_vote'>
                                    <FontAwesomeIcon onClick={inLikeList ? () => {
                                        removeMovieFromUpVotes(movieInf.id);
                                        console.log(inLikeList)
                                    } : () => {
                                        addMovieToUpVotes(movieInf.id);
                                        console.log(inLikeList)
                                    }} icon={faThumbsUp} style={LikeButton}/>
                                    <FontAwesomeIcon onClick={inDislikeList ? () => {
                                        removeMovieFromDownVotes(movieInf.id)
                                    } : () => {
                                        addMovieToDownVotes(movieInf.id)
                                    }} icon={faThumbsDown} style={dislikeButton}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>)}
