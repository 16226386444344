import React, {useEffect, useRef, useState} from 'react'
import CinimaSeats from './CinimaSeats'
import './Room.css'
import {getDatabase, ref, set, onValue, update} from "firebase/database";
import {useParams} from "react-router-dom";
import {leaveRoom, createRoom, logToRoom, getRoomData, setRoomData, setRoomState} from '../Auth/FireBase.js';
import {useNavigate} from "react-router-dom";
import {serverRoute} from "../globalComponents/global_vars";

const Room = (props) => {

    const navigate = useNavigate();

    const db = getDatabase();
    const params = useParams();
    const id = params.id;
    const [roomData, setRoomData] = useState({})
    useEffect(() => {
        return onValue(ref(db, '/Rooms/' + id), (snapshot) => {
            const Data = snapshot.val() || 'null';
            console.log(Data, 'firebase')
            console.log(roomData)
            if (Data?.state?.play !== roomData?.state?.play) {
                console.log('dif')
                Data.state.play ? play() : pause();
            }
            if (videoPlayer.current.currentTime <= Data.state.time-2||videoPlayer.current.currentTime >= Data.state.time+2){
                videoPlayer.current.currentTime = Data.state.time-1;
            }
            setRoomData(Data)
            return () => {
                leaveRoom(id);
            }
        })
    }, [])

    const videoPlayer = useRef();
    const [currentTime, setCurrentTime] = useState(0);
    const [seekValue, setSeekValue] = useState(0);

    const play = () => {
        console.log("play")
        videoPlayer.current.play();
        setRoomState(id,currentTime,true);
    };

    const pause = () => {
        videoPlayer.current.pause();
        setRoomState(id,currentTime,false);
        console.log({id,currentTime,play:false})

        console.log("pause")
    };



    const onPlaying = () => {
        console.log(videoPlayer.current.currentTime);
        setCurrentTime(videoPlayer.current.currentTime);
        // setRoomState(id,currentTime,roomData.state.play);
        setSeekValue(
            (videoPlayer.current.currentTime / videoPlayer.current.duration) * 100
        );
    };

    function handleLeaveRoom() {
        leaveRoom(id);
        navigate('/main');
    }

    return (
        <div className='room-container'>
            <button onClick={() => handleLeaveRoom()} style={{position:"fixed",top:"100px",left:"100px",zIndex:1000}}>exit room</button>

            <div className='video-container'>
                <video
                    id="videoPlayer"
                    controls
                    muted="muted"
                    ref={videoPlayer}
                    autoPlay
                    onTimeUpdate={onPlaying}
                    onPlay={play}
                    onPause={pause}
                    style={{width:"75vw",height:"75vh"}}
                >
                    {/*<source src={`${serverRoute}/video/${id}`} type="video/mp4" />*/}
                    <source src={`${serverRoute}/video/370172`} type="video/mp4"/>
                </video>
            </div>
            <div className='viewer-container'>
                <CinimaSeats className='user-avatar'/>
            </div>

        </div>
    )
}

export default Room
