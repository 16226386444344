import React from 'react'
import {Routes, Route, Navigate} from "react-router-dom";
import MainScreen from '../MainScreen/MainScreen';
import SignUp from '../Auth/SignUp/SignUp';
import SignIn from '../Auth/SignIn/SignIn';
import Room from '../watchSession/Room';
import ProfilePage from '../profile/profilePage';
import {useUserContext} from '../Auth/ConnectedUserContext';
import Comp from "../MainScreen/logo";
import Search from "./search";

const RouteRestrictor = () => {
    const {currentUser} = useUserContext()

    //todo- loading if user is connected or not

    return (
        !currentUser ? <Routes>
                {/*non user routes*/}
                <Route path="/sign_up" element={<SignUp/>}/>
                <Route path="/sign_in" element={<SignIn/>}/>
                <Route path='*' element={<Navigate to="/sign_in"/>}/>
            </Routes>
            :
            <Routes>
                {/*open routes */}
                <Route path="/home" element={<Comp/>}/>
                <Route path="/sign_up" element={<SignUp/>}/>
                <Route path="/sign_in" element={<SignIn/>}/>
                <Route path="/main" element={<MainScreen/>}/>
                <Route path='/Profile' element={<ProfilePage/>}/>
                <Route path='Room/:id' element={<Room style={{overflowY:"none"}}/>}/>
                <Route path='/Search' element={<Search/>}/>
                <Route path='*' element={<Navigate to="/home"/>}/>
            </Routes>
    )
}

export default RouteRestrictor
