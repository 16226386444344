import React, {useState, useEffect, useRef} from 'react'
import axios from '../tmdbAPI/axios';
import './Row.css';
import MoviePoster from './MoviePoster';
import {sideScroll} from '../globalComponents/global_vars'

function Row({title, url, isLarge, pop, insertInf}) {

    const [movies, setMovies] = useState([]);

    const windowRef = useRef(null);

    const scrollAmount = isLarge?220:280;

    async function getData() {
        const request = await axios.get(url);
        setMovies(request.data.results);
    }
    useEffect(() => {
        getData();
    }, [url]);

    return (
        <div className="row">
            <h2>{title}</h2>
            {
                <div>

                    <div className={`row_posters`} ref={windowRef} style={{scrollBehavior:"smooth"}}>

                        {movies.map((movie) =>
                            <MoviePoster key={movie.id} movie={movie} isLarge={isLarge} pop={pop}
                                         insertInf={insertInf} />
                        )}
                        {/*loop data*/}

                    </div>
                    <div style={{textAlign:"center"}}>
                                <button className='arrow_btn arrow_left' onClick={() => {
                                    sideScroll(windowRef.current, 25, 100, -1*scrollAmount);
                                }}>&lt;</button>
                        <button className='arrow_btn arrow_right' onClick={() => {
                                    sideScroll(windowRef.current, 25, 100, scrollAmount);
                                }}>&gt;</button>
                    </div>

                </div>

            }
        </div>
    )
}

export default Row
