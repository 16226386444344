import React,{useRef,useEffect, useState} from 'react'
import {logInFunction, resetPass, signUpWithGoogle} from '../FireBase'
import {Link,useNavigate} from 'react-router-dom';
import {useUserContext} from '../ConnectedUserContext';
import LoadingPage from '../../globalComponents/LoadingPage';
import '../style.css'
import {sendPasswordResetEmail} from "firebase/auth";



function SignIn () {
    const emailRef = useRef("")
    const passwordRef = useRef("")
    const [alert, setAlert] = useState('')
    const [loading,setLoading]=useState(false)
    const {currentUser} = useUserContext()
    const nav = useNavigate();
    async function heandleSubmit(e){
        e.preventDefault();
            try{
                setLoading(true)
                setAlert("");
                await logInFunction(emailRef.current.value,passwordRef.current.value)
            }catch{
                setAlert("Faild to connect")
            }
            setLoading(false)
            return
    }
    useEffect(()=>{
        currentUser&& nav('/home',{replace:true})
    },[currentUser,nav])


    return (
        loading ? <LoadingPage/> :
        <>
        <div className='container'style={{width:'100%',height:'100vh',alignItems:'center',justifyContent:'center'}}>
        
                {alert && <div className='danger'>{alert}</div>}
                <form  id='form' className='animated' style={{height:'50vh'}} onSubmit={heandleSubmit}>
                <h2>Log In</h2>
                    <div className='detail'>
                        <div className="form_row">
                            <div className="col-12">
                                <input id='email' ref={emailRef}  name="email" placeholder={'Email'} />
                            </div>
                        </div>
                        <div className="form_row">
                            <div className="col-12">
                                <input id='password' ref={passwordRef} name='password' placeholder={'password'} type='password' />
                            </div>
                        </div>
                        <div className="form_row">
                    <button disabled={loading} type="submit" >Log In</button>
                    <button disabled={loading} type='button' onClick={()=>{setLoading(true);signUpWithGoogle()}} >Log In with Google</button>
                    </div>
                    </div>
                    <div className="w-100 text center mt-2">
                Dont have an account ?<Link to="/sign_up"> Sign Up</Link>

            </div>
                </form>
            <div className="form_row" style={{color:"gray"}}>
                <label
                    style={{transform:'translateX(45vw)'}}
                >
                    Forgot password ?
                </label>
                <button
                    style={{width:"20vw"}}
                    onClick={
                        ()=>{
                            resetPass(emailRef.current.value);
                        }
                    }
                >
                    Reset Password
                </button>

            </div>
                    
        
         
        </div>
        </>
    )
}

export default SignIn
