import React,{useState,useEffect,useRef} from 'react'
import Avatar from '../Avatars/avatars'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBars, faHouseUser, faSearch, faUser} from '@fortawesome/free-solid-svg-icons'
import { useUserContext } from '../Auth/ConnectedUserContext';
import { logToRoom, SignOut } from '../Auth/FireBase';
import { useNavigate } from "react-router-dom";
import {
    Styles,
    Navbar,
    HamburgerButton,

    NavLink,
    NavBtn,

}from './NavbarElements'


const SideMenu = ({show,avatar}) => {
  const navigate = useNavigate();

    const { currentUser,profile } = useUserContext()
    const [openDrawer, toggleDrawer] = useState(false);
    const drawerRef = useRef(null);
     useEffect(() => {
      /* Close the drawer when the user clicks outside of it */
      const closeDrawer = event => {
        if (drawerRef.current && drawerRef.current.contains(event.target)) {
          return;
        }
        toggleDrawer(false);
      };
      document.addEventListener("mousedown", closeDrawer);
      return () => document.removeEventListener("mousedown", closeDrawer);
    }, []);



    return (
        <>
         <Styles.Wrapper>
          <Navbar.Wrapper>
          <HamburgerButton.Wrapper onClick={() => toggleDrawer(true)}>
            <HamburgerButton.Lines />
          </HamburgerButton.Wrapper>
            <div>
                <FontAwesomeIcon icon={faBars} />
            </div>
          <div  className='nav_avatar'  onClick={
              ()=>{
                  toggleDrawer(!openDrawer)
              }
          }>
          <Avatar index={avatar}style={{ opacity: show ? '1' : '0.4' }}/>
        </div>
              { currentUser ?
          <Navbar.Items ref={drawerRef} openDrawer={openDrawer}>
          <Navbar.Item><NavLink to='/main'>
              <FontAwesomeIcon style={{width:"50px",height:"30px"}} icon={faHouseUser}></FontAwesomeIcon> Home Screen
          </NavLink></Navbar.Item>
          <Navbar.Item><NavLink to='/Profile'>
            <FontAwesomeIcon style={{width:"50px",height:"30px"}} icon={faUser}></FontAwesomeIcon>  Profile
          </NavLink></Navbar.Item>
              <Navbar.Item><NavLink to="Search">
                  <FontAwesomeIcon style={{width:"50px",height:"30px"}} icon={faSearch}>Search</FontAwesomeIcon> Search
              </NavLink></Navbar.Item>
          <Navbar.Item><NavBtn onClick={()=>{
              let code = prompt('please enter room code')
              if(code){
                logToRoom(code,profile);
                navigate('/Room/'+code)
              }
          }}>Join Room</NavBtn></Navbar.Item>
              <Navbar.Item><NavBtn onClick={()=>{
              SignOut();
          }}>Log out</NavBtn></Navbar.Item>
          </Navbar.Items>:""}
        </Navbar.Wrapper>
        </Styles.Wrapper>

        </>
    )
}

export default SideMenu
