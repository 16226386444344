import React, {useEffect, useRef, useState} from 'react'
import { useUserContext } from '../Auth/ConnectedUserContext'
// todo - keep and use or delete - was never used
// import { useNavigate } from 'react-router'
import Row from './Row'
import requests, {getMovieData} from '../tmdbAPI/requests'
import Banner from './Banner'
import LoadingPage from '../globalComponents/LoadingPage'
import './mainScreen.css'
import {isLoggedIn, loadWatchlist} from '../Auth/FireBase.js'
import PopUp from "./popup";
import MoviePoster from "./MoviePoster";
import {sideScroll} from "../globalComponents/global_vars";

const MainScreen = () => {
    const {profile}= useUserContext();
    const [popupBool,pop]=useState(false);
    const [movieInf,insertInf] = useState({});
    const [rowNum,setRowNum] = useState(0)

    const [rec_movies, set_rec_movies] = useState([]);
    const [ready, setReady] = useState(false);
    const windowRef = useRef(null);

    const [movieId,setMovieId] = useState([]);

    useEffect(()=>{
        isLoggedIn();
        },[popupBool,rowNum,movieId]);

    useEffect(() => {
        loadWatchlist({movieId,setMovieId});


        let movie_promise_array = []
        if (profile?.userMovies?.userRecomended?.length > 6) {
            profile?.userMovies?.userRecomended.forEach(element => {
                let movie_promise = getMovieData(element.id)
                movie_promise_array.push(movie_promise)
            })
            Promise.allSettled(movie_promise_array).then((e) => {
                    set_rec_movies(e)
                    setReady(true)
                }
            )
        }
    }, []);



    return (
        !profile? <LoadingPage/>:
        <div className='main_page_container'>

            {/*main moive*/}
            <Banner/>
            <nav id="horizontal-navigation" className="horizontal-navigation">
                <ul className="scroll-nav">
                    <li>
                        <button
                            className="banner_button"
                            onClick={()=>{setRowNum(1)}}
                        >
                            Adventure
                        </button>
                    </li>
                    <li>
                        <button
                            className="banner_button"
                            onClick={()=>{setRowNum(2)}}
                        >
                            Comedy
                        </button>
                    </li>
                    <li>
                        <button
                            className="banner_button"
                            onClick={()=>{setRowNum(3)}}
                        >
                            Romance</button>
                    </li>
                    <li>
                        <button
                            className="banner_button"
                            onClick={()=>{setRowNum(4)}}
                        >
                            Animation</button>
                    </li>
                    <li>
                        <button
                            className="banner_button"
                            onClick={()=>{setRowNum(5)}}
                        >
                            Fantasy</button>
                    </li>
                    {ready&&<li>
                        <button
                            className="banner_button"
                            onClick={()=>{setRowNum(6)}}
                        >
                            My List</button>
                    </li>}
                    {movieId.length>0&&<li>
                        <button
                            className="banner_button"
                            onClick={()=>{setRowNum(7)}}
                        >

                            Recommended</button>
                    </li>}
                </ul>
            </nav>
            {/*larg row */}
            <Row title="Trending " url={requests.getTrending} isLarge pop={pop} insertInf={insertInf}/>
            <Row title="Top rated " url={requests.getTopTated} pop={pop} insertInf={insertInf}/>
            {rowNum===7 && ready &&
            <div className="row">
                <h2>Recommended</h2>
                {
                    <div>
                        <div className={`row_posters`} ref={windowRef} style={{ scrollBehavior: "smooth" }}>
                            {
                                rec_movies && rec_movies?.map((movie) =>
                                    movie.status === "fulfilled" ?
                                        <MoviePoster key={movie.value.data.id} movie={movie.value.data} isLarge={true} pop={pop}
                                                     insertInf={insertInf} />
                                        : ''
                                )}
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <button className='arrow_btn arrow_left' onClick={() => {
                                sideScroll(windowRef.current, 25, 100, -1 * 220);
                            }}>&lt;</button>
                            <button className='arrow_btn arrow_right' onClick={() => {
                                sideScroll(windowRef.current, 25, 100, 220);
                            }}>&gt;</button>
                        </div>
                    </div>
                }
            </div> }
            {rowNum===6 &&
                <div className="row">
                    <h2>My List</h2>
                    {
                        <div>
                            <div className={`row_posters`} ref={windowRef} style={{ scrollBehavior: "smooth" }}>
                                {
                                    movieId && movieId?.map((movie) =>
                                        movie?.status == "fulfilled" ?
                                            <MoviePoster key={movie.value.data.id} movie={movie.value.data} isLarge={true} pop={pop}
                                                         insertInf={insertInf} />
                                            : ''
                                    )}
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <button className='arrow_btn arrow_left' onClick={() => {
                                    sideScroll(windowRef.current, 25, 100, -1 * 220);
                                }}>&lt;</button>
                                <button className='arrow_btn arrow_right' onClick={() => {
                                    sideScroll(windowRef.current, 25, 100, 220);
                                }}>&gt;</button>
                            </div>
                        </div>
                    }
                </div>}

            {rowNum===1&&<Row title="Adventure" url={requests.getAdventureList} pop={pop} insertInf={insertInf}/>}
            {rowNum===2&&<Row title="Comedy" url={requests.getComedyList} pop={pop} insertInf={insertInf}/>}
            {rowNum===3&&<Row title="Romance" url={requests.getRomanceList} pop={pop} insertInf={insertInf}/>}
            {rowNum===4&&<Row title="Animation" url={requests.getAnimationList} pop={pop} insertInf={insertInf}/>}
            {rowNum===5&&<Row title="Fantasy" url={requests.getFantasyList} pop={pop} insertInf={insertInf}/>}
            {/*<Row title = "Animation" url={requests.getAnimationList}></Row>    */}

            {/*  rows  props - > title , url , isLarge*/}




            <div>
                {popupBool?<PopUp
                    pop={pop}
                    movieInf={movieInf}
                    url={requests.getTrailers}
                />:null}
            </div>
        </div>
    );
}

export default MainScreen
