import React,{useState,useEffect} from 'react'
import LoadingComponent from '../globalComponents/LoadingComponent';
import axios from '../tmdbAPI/axios';
import requests from '../tmdbAPI/requests';
import './Banner.css'

import {img_base_url, truncate, makeid, wordTruncate, callCreateNewRoom} from '../globalComponents/global_vars';
import { createRoom ,logToRoom} from '../Auth/FireBase';
import { useUserContext } from '../Auth/ConnectedUserContext';
import { useNavigate } from "react-router-dom";

const Banner = () => {
    const [movie,setMovie]=useState();
    const [loading,setLoading]= useState(true);
    const navigate = useNavigate();
    const {profile} = useUserContext()

    useEffect(()=>{

        setLoading(true)
        async function getData(){
            const request = await axios.get(requests.getPopuler);
            setMovie(
                request.data.results[
                Math.floor(Math.random(request.data.results.length-1))
                ]
            )
            return request;
        }
        getData();
    },[])
    useEffect(()=>{
            movie&&setLoading(false)
            // movie&&callCreateNewRoom(movie)
    },[movie])


    return (
        loading ? <div style={{height: 450,backgroundColor:'black',justifyContent:'center',alignItems:'center'}}> <LoadingComponent/> </div>:
        <header className="banner"
            style={{
                backgroundSize:"cover",
                backgroundImage:`url(${img_base_url}${movie?.backdrop_path})`,
                backgroundPosition:"center center",
            }}
        >{/*background img*/}
            <div className="banner_contents">
                <h1 className="banner_title">{`${movie?.original_title||movie?.name||movie?.title}`}</h1>
                {/*title */} 
                <div className="banner_buttons">


                        <button className="banner_button" onClick={() => callCreateNewRoom(movie,profile,navigate)} >Play</button>


                    <button className="banner_button">Add to List</button>
               </div>
                {/*buttons*/}
                {/*discription*/}
                <h1 className="banner_description">
                    {wordTruncate(movie?.overview,50)}
                </h1>
            </div>
            <div className="banner--fadeBottom"/>
        </header>
    )
}

export default Banner
