import React, {useEffect, useState} from 'react'
import { useUserContext } from '../Auth/ConnectedUserContext'
import Avatar ,{ avatars } from '../Avatars/avatars';
import {updateAvatar, updateUserInfo} from '../Auth/FireBase';
import { loadProfile } from '../Auth/FireBase';
import './profilePage.css'
import {getMovieData} from "../tmdbAPI/requests";

function ProfilePage() {
    const {profile,setProfile}= useUserContext();
    const [avatar_drawr,openDrawer] = useState(false)
    const [enabled,setEnable] = useState(true);
    const [firstname,setFirstname] = useState(profile&&profile.userProfileName)
    const [email,setEmail] = useState(profile&&profile.email)
    const [refresh,setRefresh] = useState(false);

    function LoadProfile(){
        loadProfile({profile,setProfile})
        console.log(profile);
    }

    useEffect(()=>{
        setRefresh(false);
    },[refresh])

    return (
        <div className='profile_page_container' >
            <div className="profile_info_display">
            <form className='animated profile_from'>
                    <div className='detail' onDoubleClick={()=>{setEnable(!enabled)}}>
                        <div className="profile_from_row">
                            <div className="col-12" >
                               <input id='name'  name="firstname" onChange={(e)=>{setFirstname(e.target.value)}}  disabled={enabled} value={firstname} />
                            </div>
                        </div>
                        <div className="profile_from_row">
                            <div className="col-12">
                                <input id='name'  name="email"  onChange={(e)=>{setEmail(e.target.value)}} disabled={enabled} value={email} />
                            </div>
                        </div>
                        <div className="profile_from_row">
                            <div className="col-12">
                                <button id='submit' type='reset' onClick={()=>{
                                    updateUserInfo({email:email,firstname:firstname})
                                }}>Send</button>
                            </div>
                        </div>
                    </div>
                    <div className='user_profile_avatar' onClick={()=>openDrawer(!avatar_drawr)}>
                    <Avatar className='profile_avatar' index={profile.userAvatar}/>
                    </div>
                    <div className='select_avatar_row' style={{visibility:avatar_drawr?'visible':'hidden', opacity:avatar_drawr?1: 0}}>
                        {avatars.map((AV, i) => <div key={i} className="avatar_options"  
                        onClick={()=>{updateAvatar(i);LoadProfile()}}>
                        <Avatar index={i}/></div>)}
                    </div>
                </form>
           </div>
            <div className='movie_history_row'>

            </div>
            
            
        </div>
    )
}



export default ProfilePage
