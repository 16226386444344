import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, onValue, update,remove,get } from "firebase/database";
import { getAuth, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, signOut, signInWithEmailAndPassword, updateEmail, sendEmailVerification, updatePassword, sendPasswordResetEmail ,confirmPasswordReset } from "firebase/auth";
import 'firebase/auth'
import {getMovieData} from "../tmdbAPI/requests";
import Swal from 'sweetalert2';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_USER_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MESURMENT_ID
};
export const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const db = getDatabase();

const auth = getAuth(app);
const baseAvatar = 1;

export function signUpWithMail(email, password, profileName) {
    createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            writeUserData(user.uid, profileName, email)
            // ...
        })
        .catch((error) => {
            // todo - keep and use or delete - was never used
            //const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorMessage)
            // ..
        });
}
export function signUpWithGoogle() {
    signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // todo - keep and use or delete - was never used
            //const credential = GoogleAuthProvider.credentialFromResult(result);
            //const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            writeUserData(user.uid, user.displayName, user.email)
            // ...
        }).catch((error) => {
            // Handle Errors here.
            // todo - keep and use or delete - was never used
            //const errorCode = error.code;
            // todo - keep and use or delete - was never used
            //const errorMessage = error.message;
            // The email of the user's account used.
            // todo - keep and use or delete - was never used
            //const email = error.email;
            // The AuthCredential type that was used.
            // todo - keep and use or delete - was never used
            //const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });

}

export function logInFunction(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
}


export async function SignOut() {
    signOut(auth).then(() => {
        console.log('success', auth)
        return true;
    }).catch((error) => {
        return false;
    });
}

function writeUserData(userId, name, email, avatarIndex) {
    set(ref(db, 'users/' + userId), {
        userProfileName: name,
        email: email,
        userAvatar: avatarIndex || baseAvatar,
        userMovies: [],
        userPaid: false
    });
}

export async function loadWatchlist({movieId, setMovieId}){
    let array = [];
    const userId = auth.currentUser.uid;
    onValue(ref(db, '/users/' + userId+'/userMovies/userWatchList'), (snapshot) => {
        const movie = (snapshot.val() && snapshot.val()) || 'Anonymous';
        console.log(movie)
        if(movie!='Anonymous'){
            movie.forEach(  (item)=>{
                let moviePromise = getMovieData(item);
                console.log(moviePromise)
                array.push(moviePromise);


            })
            Promise.allSettled(array).then( (e) => {
                    setMovieId( e)

                }
            )
        }

    }, {
        onlyOnce: true
    })
}

export function loadProfile({ profile, setProfile }) {
    const userId = auth.currentUser.uid;
    return onValue(ref(db, '/users/' + userId), (snapshot) => {
        const username = (snapshot.val() && snapshot.val()) || 'Anonymous';
        setProfile(username)
    }, {
        onlyOnce: true
    });
}
export function loadProfileNoState(){

}

export function isLoggedIn(){
    return (auth.currentUser);
}

export function updateAvatar(index) {

    // A post entry.

    update(ref(db, '/users/' + auth.currentUser.uid), { '/userAvatar': index })
}

export function updateUserInfo(info) {

    // A post entry.

    update(ref(db, '/users/' + auth.currentUser.uid), { '/email': info.email,'/userProfileName': info.firstname })
}

export function changeEmail(Email) {
    updateEmail(auth.currentUser, Email).then(() => {
        // Email updated!
        // ...
    }).catch((error) => {
        // An error occurred
        // ...
    });
}

export function EmailVerification() {
    sendEmailVerification(auth.currentUser)
        .then(() => {
            // Email verification sent!
            // ...
        });
}
export function changePassword(newPassword) {
    const user = auth.currentUser;
    updatePassword(user, newPassword).then(() => {
        // Update successful.
    }).catch((error) => {
        // An error ocurred
        // ...
    });
}

export function addToWatchList(watchList, movieID) {
    if (watchList.includes(movieID) === false) watchList.push(movieID)
    else console.log('movie in list', watchList)
    update(ref(db, '/users/' + auth.currentUser.uid + '/userMovies'), { '/userWatchList': watchList })
}
export function removeFromWatchList(watchList, movieID) {
    watchList = watchList.filter(val => val !== movieID);
    update(ref(db, '/users/' + auth.currentUser.uid + '/userMovies'), { '/userWatchList': watchList })
}
export function addToLikedList(movieList, movieID) {
    if (movieList.includes(movieID) === false) movieList.push(movieID)
    else console.log('movie in list', movieList)
    update(ref(db, '/users/' + auth.currentUser.uid + '/userMovies'), { '/userUpVotes': movieList })
}
export function removeFromLikedList(movieList, movieID) {
    movieList = movieList.filter(val => val !== movieID);
    update(ref(db, '/users/' + auth.currentUser.uid + '/userMovies'), { '/userUpVotes': movieList })
}
export function addToDislikedList(movieList, movieID) {
    if (movieList.includes(movieID) === false) movieList.push(movieID)
    else console.log('movie in list', movieList)
    update(ref(db, '/users/' + auth.currentUser.uid + '/userMovies'), { '/userDownVotes': movieList })
}
export function removeFromDislikedList(movieList, movieID) {
    movieList = movieList.filter(val => val !== movieID);
    update(ref(db, '/users/' + auth.currentUser.uid + '/userMovies'), { '/userDownVotes': movieList })
}

// todo - to delete
export function roomExists(roomID){
    ref.child("users").orderByChild("ID").equalTo("U1EL5623").once("value",snapshot => {
        if (snapshot.exists()){
          const userData = snapshot.val();
          console.log("exists!", userData);
        }
    });
}

// V
export function createRoom(roomID,roomSettings){
    set(ref(db, 'Rooms/' + roomID), {
        state:{
            movideID:roomSettings.movideID,
            play:false,
            time:roomSettings.movieTime,
            votesToPause:{
                0:false,
            }
        },
        creator: auth.currentUser.uid,
        
    });
}

export function logToRoom(roomID,profile){
    console.log(profile)
    get(ref(db, `/Rooms/${roomID}`)).then((snapshot) => {
        console.log(snapshot.val(), 'this is my snapshot')
        if (snapshot.val()==null) {
            console.log('nope')
            alert('there is no such room');
        } else {
            console.log(snapshot)
            update(ref(db, `/Rooms/${roomID}/Users/`+auth.currentUser.uid), {
                avatar:profile.userAvatar,
                profile:profile.userProfileName,
                stop:false,
                })
        }
      }).catch((error) => {
        console.error(error);
      });
}

export function leaveRoom(roomID){
    get(ref(db, `/Rooms/${roomID}`)).then((snapshot) => {
        if (snapshot.exists()) {
            let roomCreator =snapshot.val()
            if (roomCreator.creator == auth.currentUser.uid){
                set(ref(db, `/Rooms/${roomID}/`), {
                    0 : null
                      })
            }
            else {
                set(ref(db, `/Rooms/${roomID}/Users/`+auth.currentUser.uid), {
                    0 : null
                      })
            }
        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });
 
  
}
// V in Room component
export function getRoomData({roomData,setRoomData},roomID){
    //     return onValue(ref(db, '/Rooms/' + roomID), (snapshot) => {
    //     const Data = snapshot.val()|| 'null';
    //     console.log(Data,'firebase')
    //     setRoomData(Data)
    // }, {
    //     onlyOnce: true
    // })
}
export function setRoomData(){
    // update(ref(db, '/users/' + auth.currentUser.uid + '/userMovies'), { '/userDownVotes': movieList })
}
export function setRoomState(room,time,play){
    console.log('update')
    console.log({ '/play': play, '/time':time})
    update(ref(db, '/Rooms/' + room + '/state'), { '/play': play, '/time':time})
}

export function resetPass(emailAddress){
    if(emailAddress=="" || !/.+[@].+\..+/.test(emailAddress)){
        Swal.fire({
            icon:"warning",
            title:"Enter a valid email please"
        })
        return;
    }

    sendPasswordResetEmail(auth,emailAddress).then(function() {
        // Email sent.
        Swal.fire({
            icon:"success",
            title:"Email sent!"
        })
        console.log('email sent!');
    }).catch(function(error) {
        // An error happened.
        Swal.fire({
            icon:"error",
            title:"Email was not sent!"
        })
        console.error('email wasnt sent!');
    });
}

export default app