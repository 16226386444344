import React, { useEffect, useState } from 'react'
import 'react-pro-sidebar/dist/css/styles.css';
import './header.css';
import { useUserContext } from '../Auth/ConnectedUserContext';
import SideMenu from './SideMenu';
import {NavLink} from "react-router-dom";

function Header() {

    const [show, setShow] = useState(false);
    const { profile } = useUserContext();
    useEffect(() => {
        // show bar on scrole
        const onScroll = (e) => {
            if (window.scrollY > 100)
                setShow(true);
            else
                setShow(false);
        };
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", this);
        };
    }, []);

    return (
        <>
            <div className={`nav ${show && "nav_black"}`} style={{display:profile?'':'none'}}
             onMouseEnter={() => { setShow(true) }}
              onMouseLeave={() => { setShow(false) }}>
                 {/*^ show bar on hover ^*/}
                <NavLink to={'main'}><img src={window.location.origin+"/Polithedrum-logos.jpeg"} style={{borderRadius:"20px",width:"4vw",marginTop:"2px"}} className="nav_logo" alt='nav_logo'/></NavLink>

                <div className='profile_container'>

                    <div className='nav_profile' style={{ opacity: show ? '1' : '0.4' }}>hello {profile && profile.userProfileName}</div>
                     <SideMenu avatar={profile ? profile.userAvatar:1} show={show}/>
                </div>

            </div>
            {/*avatar + side menu  */}
            {/* catagorie menu */}
            {/* logo */}
        </>

    )
}

export default Header
