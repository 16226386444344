import React from 'react'
import {img_base_url,wordTruncate} from '../globalComponents/global_vars';


const MovieDescription = ({ title, description, img }) => {
    const img2={
        backgroundImage:`url("${img_base_url}${img}")`,
        backgroundSize:"100%"
    }
    const text={
        backgroundColor:`rgba(0, 0, 0, 0.35)`
    }
    return (
        <div className='movie_description' style={img2}>
            <div className='movie_description_text' style={text} >
                <h1>
                    {title}
                </h1>
                <p>
                    {wordTruncate(description,30)}
                </p>
            </div>
        </div>
    )
}

export default MovieDescription

