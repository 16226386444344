import {createRoom, logToRoom} from "../Auth/FireBase";

export function truncate(str, n){
    return (str?.length > n) ? str.substr(0, n-1) + '...' : str;
};

export function wordTruncate(str,n){
    let arr = str?.split(" ");
    return (arr.length>n) ? arr.slice(0,n).join(" ")+"...":str;
}

export const img_base_url = "https://image.tmdb.org/t/p/original/";

export const sideScroll = (
    element,
    speed,
    distance,
    step
) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
        element.scrollLeft += step;
        scrollAmount += Math.abs(step);
        if (scrollAmount >= distance) {
            clearInterval(slideTimer);
        }
    }, speed);
};

export const serverRoute = `https://api.thepcport.com/movie`;
export const IMAGE_PATH = "https://image.tmdb.org/t/p/w342"


export function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}

export const OVERLAY = {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.7)',
    zIndex: 8000
}

export const playButton = {width: "3vw", height: "5vh", position: "fixed", top: "60vh", left: "27vw"};

export function callCreateNewRoom(movie,profile,navigate){
    let roomId = makeid(7);
    let roomSettings = {
        movideID:movie.id,
        movieTime:0,
    }
    console.log(movie,roomSettings)
    createRoom(roomId,roomSettings);
    logToRoom(roomId,profile)
    navigate('/Room/'+roomId)
}
