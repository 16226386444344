import React from 'react'

import { ReactComponent as AV2 } from '../Avatars/avatar-3.svg';
import { ReactComponent as AV3 } from '../Avatars/avatar-2.svg';
import { ReactComponent as AV4 } from '../Avatars/avatar-13.svg';
import { ReactComponent as AV7 } from '../Avatars/avatar-22.svg';
import { ReactComponent as AV8 } from '../Avatars/avatar-21.svg';
import { ReactComponent as AV9 } from '../Avatars/avatar-11.svg';

export const avatars = [AV2,AV3,AV4,AV7,AV8,AV9];

const Avatar = ({index}) => {
    const Selected = avatars[index];
    return (
        <Selected/>
    )
}

export default Avatar
