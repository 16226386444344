import styled, { createGlobalStyle } from "styled-components";
import { NavLink as Link } from 'react-router-dom';

export const Styles = {
    Wrapper: styled.main`
      display: flex;
      font-size: large;
      width: fit-content;
      margin: 0 auto;
      
    `
  };
  
  export const Navbar = {
    Wrapper: styled.nav`
      flex: 1;
      align-self: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;

  
      // 40em == 640px
      
    `,
    Logo: styled.h1`
      border: 1px solid gray;
      padding: 0.5rem 1rem;
    `,
    Items: styled.ul`
      display: flex;
      flex-direction: column;
      list-style: none;
      align-items: center;
      justify-content: center;
      position: absolute;
      transition: 0.2s ease-in-out;
      right: 0;
      background-color: #111;
      border-radius: 10px;
      width: max-content;
      padding: 0;
      top:5.5vh;
      transform: ${({ openDrawer }) =>
        openDrawer ? `translateX(0%)` : `translateX(140%)`};
        
    `,
    Item: styled.li`
      padding: 0.6rem 2rem;
      cursor: pointer;
      color:whitesmoke;
      font-size:18px;
      border-radius:10px;
      transition: 400ms ease-in;
      width: 11ch;
     
      text-shadow:
        0.05em 0 black,
        0 0.05em black,
        -0.05em 0 black,
        0 -0.05em black,
        -0.05em -0.05em black,
        -0.05em 0.05em black,
        0.05em -0.05em black,
        0.05em 0.05em black;
      &:hover{
        background-color: rgb(73, 27, 116);
      }

      font-weight: 500;
      
    `
  };
  export const NavBtn = styled.button`
  color: #fff;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  justify-content: center;
  border-radius:50px;
  font-size:18px;
  width: 11ch;
  &.active {
    color: whitesmoke;
    font-size:14pt;
  }
  @media only screen and (max-width: 768px) {
    
  }
  `;
  export const NavLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  margin: 0 auto;
  width: 100%;
  &.active {
    color: whitesmoke;
    border-bottom: solid grey 0.7px;
  }
 &:hover{
   color:whitesmoke;
 }
  
`;
  
  export const HamburgerButton = {
    Wrapper: styled.button`
      height: 4rem;
      width: 4rem;
      position: inherit;
      font-size: 12px;
  
      display: none;
  
      
  
      /* Remove default button styles */
      border: none;
      background: transparent;
      outline: none;
  
      cursor: pointer;
      background-color: rgba(115, 194, 37,0.7);
      border-radius:30px 0 30px 0;

  
      &:after {
        content: "";
        display: block;
        position: absolute;
        height: 150%;
        width: 150%;
        top: -25%;
        left: -25%;
      }
    `,
    Lines: styled.div`
      top: 50%;
      margin-top: -0.125em;
  
      &,
      &:after,
      &:before {
        /* Create lines */
        height: 2px;
        pointer-events: none;
        display: block;
        content: "";
        width: 100%;
        background-color: black;
        position: absolute;
      }
  
      &:after {
        /* Move bottom line below center line */
        top: -0.8rem;
      }
  
      &:before {
        /* Move top line on top of center line */
        top: 0.8rem;
      }
    `
  };
  
  export const CSSReset = createGlobalStyle`
    *,
    *::before, 
    *::after {
      margin: 0; 
      padding: 0;
      box-sizing: inherit;
    }
  
    html {
      font-size: 62.5%; /*1rem = 10px*/
      box-sizing: border-box;      
    }  
  
    body {
      font-size: 1.4rem;
      font-family: sans-serif;
    }
  `;

  export const dropMenuButton = {
    Item: styled.button`
    display: flex;
    background-color:rgba(0, 0,0, 0);
    border:none;
    font-size: large;
    padding: 0 1rem;
    margin: 0 auto;
    font-size:14pt;
    cursor: pointer;
    align-items: center;
    font-weight: bold;
      cursor: pointer;
      color:whitesmoke;
      text-shadow:
      0.05em 0 black,
      0 0.05em black,
      -0.05em 0 black,
      0 -0.05em black,
      -0.05em -0.05em black,
      -0.05em 0.05em black,
      0.05em -0.05em black,
      0.05em 0.05em black;
      &:hover{
        border-style: solid;
        border-width: 0px 1px 0px 1px;
        border-color:#457346;
        border-radius:10px;
      }
      
    `
  }

  export const SubBar = {
    Wrapper: styled.nav`
      flex: 1;
      align-self: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40ch;
      margin: 0 auto;
      // 40em == 640px
      @media only screen and (max-width: 768px) {
        position: relative;
        opacity:0.9;
        z-index: 5;
      }
    `,
    Items: styled.ul`
      list-style: none;
      align-items: center;
      position: absolute;
      top:6ch;
      ${({ Lang }) =>
      Lang ? `left:0` : `right:0`};
      z-index:5;
      background:black;
      transition: 0.5s ease-in-out;
      background-color: rgb(121, 156, 128);
      padding: 0 10px;
      display: ${({ openDrawer }) =>
        openDrawer ? `block` : `none`};
      @media only screen and (max-width: 768px) {
        display:flex;
        position: fixed;
        top:5px;
        right: 0;
        min-width:min-content;
        border: 1px solid darkgreen;
        flex-direction: column;
        padding: 1rem 2rem;
        opacity: 1;
        height:55vh;
        overflow-y: scroll;
        text-overflow: clip;
        transition: 0.2s ease-in-out;
        transform: ${({openDrawer,Lang}) =>
        openDrawer ? `translateX(${Lang? `0vw`:`-20ch`})` : `translateX(300%)`};
      }
    `,
    Item: styled.li`
      position:relative;
      padding: 0 1rem;
      cursor: pointer;
      color:whitesmoke;
      font-size:18px;
      margin-bottom:5px;
      text-shadow:
        0.05em 0 black,
        0 0.05em black,
        -0.05em 0 black,
        0 -0.05em black,
        -0.05em -0.05em black,
        -0.05em 0.05em black,
        0.05em -0.05em black,
        0.05em 0.05em black;
      font-weight: 500;
      &:hover{
        background:grey;
      }
      
    `
  };