import React,{useState} from 'react'
import MovieDescription from './MovieDescription';
import {img_base_url} from '../globalComponents/global_vars';

function MoviePoster({movie, isLarge,pop,insertInf}) {
    // todo - keep and use or delete - was set but never used
    // const [showTitle, setShowTitle] = useState(false);
    // todo - keep and use or delete - was never used
    // const [loading,setLoading]=useState(true);

    const [drawr,openDrawer] = useState(false)

     /* */
    function MouseOver(event) {
        // setShowTitle(true);
        openDrawer(true)
      }
      function MouseOut(){
        // setShowTitle(false);
        openDrawer(false)
      }
      function setPopUp(){
        insertInf(movie);
        pop(true);
      }

    return (
           <>
                <img id={movie.id} className={`row_movie ${isLarge && "row_large"}`}
                    src={`${img_base_url}${
                        isLarge? movie.poster_path : movie.backdrop_path}`}
                    alt={movie.name}
                    onMouseOver={MouseOver} onMouseOut={MouseOut}
                />
                {<div className="row_movie_title"
                  style={isLarge?large_portraits:small_portraits}
                 onMouseOver={MouseOver} onMouseOut={MouseOut}
                >
                     <div  style={{visibility:drawr?'visible':'hidden', opacity:drawr?1: 0,transition:'ease-in-out 0.2s'}}>
                         <a onClick={()=>{setPopUp()}}>
                      <MovieDescription  title={movie?.title||movie?.original_title||movie?.name}
                     description={movie.overview} img={movie.poster_path}
                     />
                         </a>
                     </div>
                    </div>
                    }
            </>
    )
}

const small_portraits ={
    position:'relative',
    cursor:`pointer`,
    right:`280px`,
    width:`0px`,
    paddingRight:`-200px`
}

const large_portraits = {
    position:'relative',
    cursor:`pointer`,
    right:`210px`,
    width:`0px`,
    paddingRight:`-200px`
}


export default MoviePoster
