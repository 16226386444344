import './App.css';
import React from "react";
import Header from "./globalComponents/Header";
import { BrowserRouter as Router} from "react-router-dom";

import { AuthContextProvider } from './Auth/ConnectedUserContext';

import RouteRestrictor from './globalComponents/RouteRestrictor';

function App() {

  return (
    <>
      <Router>
        {/* provider of user auth status and profile using firebase */}
        <AuthContextProvider>
          <div className="app">
            {/*top bar shown in all pages. holds menu */}
            <Header />
            {/*custom restrictor of open routes, limits non-users from entering the site */}
            <RouteRestrictor />
          </div>

        </AuthContextProvider>
      </Router>
    </>
  );
}

export default App;
